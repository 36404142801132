import React, { useState } from 'react';
import {
  Box,
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  TextField,
  Typography
} from '@mui/material';
import { ContentCopy } from '@mui/icons-material';
import ReactPlayer from 'react-player/lazy';
import httpService from '../../../../shared/services/http.service';
import { API_CONFIG, swrOptions } from '../../../../shared/constants/constants';
import useSWR from 'swr';
import { useDispatch, useSelector } from 'react-redux';
import { NotificationType, State } from '../../../../shared/interface';
import StreamSetupLoader from './loader';
import {
  fetchStream,
  SET_EVENT_STREAM_DETAILS
} from '../../../../store/actions/eventActions';
import actionTypes from '../../../../store/action-types';

const StreamComponent = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const stream = useSelector((state: State) => state.event.activeEvent?.stream);
  const eventUuid = useSelector(
    (state: State) => state.event.activeEvent?.event?.uuid
  );
  const { isLoading } = useSWR(
    eventUuid ? API_CONFIG.PATH.EVENTS.GET_EVENT_STREAM(eventUuid) : null,
    () => fetchStream(eventUuid || ''),
    { ...swrOptions, shouldRetryOnError: false, revalidateOnMount: true }
  );

  const buttonLabel = stream
    ? stream.is_running
      ? 'Stop Stream'
      : 'Start Stream'
    : 'Create Stream';

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
    dispatch({
      type: actionTypes.SET_NOTIFICATION,
      payload: {
        message: `Copied to clipboard: ${text}`,
        type: NotificationType.SUCCESS
      }
    });
  };

  const handleStreamActions = async () => {
    setLoading(true);
    if (stream) {
      if (stream.is_running) {
        await httpService.patch(
          API_CONFIG.PATH.EVENTS.STOP_STREAM(stream.name),
          {}
        )(dispatch);
        dispatch({
          type: SET_EVENT_STREAM_DETAILS,
          payload: { data: { ...stream, is_running: false } }
        });
      } else {
        await httpService.patch(
          API_CONFIG.PATH.EVENTS.START_STREAM(stream.name),
          {}
        )(dispatch);
        dispatch({
          type: SET_EVENT_STREAM_DETAILS,
          payload: { data: { ...stream, is_running: true } }
        });
      }
    } else {
      const response = await httpService.post(
        API_CONFIG.PATH.EVENTS.CREATE_STREAM,
        {
          event_uuid: eventUuid
        }
      )();
      dispatch({
        type: SET_EVENT_STREAM_DETAILS,
        payload: { data: response }
      });
    }
    setLoading(false);
  };

  if (isLoading) {
    return <StreamSetupLoader />;
  }

  return (
    <Box sx={{ padding: 2 }}>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
      >
        <Grid item>
          {stream ? (
            <Typography variant="h5" className="capitalize">
              {stream?.name}
            </Typography>
          ) : (
            <Typography variant="h6" color="textSecondary" align="center">
              No Stream details available for this event!. please create a
              stream.
            </Typography>
          )}
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            className="capitalize"
            onClick={() => handleStreamActions()}
          >
            {buttonLabel}
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ marginTop: 2 }}>
        <Grid item xs={12} md={8}>
          <Paper sx={{ padding: 2, marginBottom: 2, minHeight: '40vh' }}>
            <ReactPlayer
              url={
                stream?.event.stream_link ||
                stream?.event.vod_link ||
                stream?.hls_link ||
                'https://live.pixsar.io'
              }
              fallback={
                <Box
                  sx={{
                    height: '40vh',
                    width: '100%',
                    backgroundColor: 'black'
                  }}
                />
              }
              controls
              width="100%"
              height="45vh"
            />
          </Paper>
          {stream ? (
            <Paper sx={{ padding: 2 }}>
              <Typography variant="h6">Comments</Typography>
              <Box sx={{ maxHeight: 400, overflowY: 'auto', marginTop: 1 }}>
                <Typography variant="body2">User1: Great stream!</Typography>
                <Typography variant="body2">
                  User2: Loving the content!
                </Typography>
              </Box>
            </Paper>
          ) : null}
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper sx={{ padding: 2, marginBottom: 2 }}>
            <Typography variant="h6">RTMP Links</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 1 }}>
              <TextField
                placeholder="rtmp://example.com/stream"
                variant="outlined"
                fullWidth
                value={stream?.rtmp_link}
                InputProps={{
                  readOnly: true
                }}
              />
              <IconButton
                onClick={() => handleCopy(stream?.rtmp_link || '')}
                edge="end"
              >
                <ContentCopy />
              </IconButton>
            </Box>
          </Paper>
          {stream ? (
            <Paper sx={{ padding: 2 }}>
              <Typography variant="h6">Setup OBS</Typography>
              <List
                component="ol"
                sx={{ listStyleType: 'decimal', paddingLeft: 4 }}
              >
                <ListItem component="li" sx={{ display: 'list-item' }}>
                  <ListItemText primary="Open OBS and go to Settings." />
                </ListItem>
                <ListItem component="li" sx={{ display: 'list-item' }}>
                  <ListItemText primary="Navigate to the “Stream” tab." />
                </ListItem>
                <ListItem component="li" sx={{ display: 'list-item' }}>
                  <ListItemText primary="Select “Custom...” in the “Service” dropdown." />
                </ListItem>
                <ListItem component="li" sx={{ display: 'list-item' }}>
                  <ListItemText primary="Enter the RTMP link in the “Server” field." />
                </ListItem>
                <ListItem component="li" sx={{ display: 'list-item' }}>
                  <ListItemText primary="Enter your stream key." />
                </ListItem>
                <ListItem component="li" sx={{ display: 'list-item' }}>
                  <ListItemText primary="Click “Apply” and then “OK”." />
                </ListItem>
              </List>
            </Paper>
          ) : null}
        </Grid>
      </Grid>
    </Box>
  );
};

export default StreamComponent;
