import useSWR from 'swr';
import { API_CONFIG } from '../../../../shared/constants/constants';
import {
  fetchEventLiveImages,
  fetchEventVideos
} from '../../../../store/actions/eventActions';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../../../shared/interface';

export function useEventLiveImages(defaultOffset?: number) {
  const dispatch = useDispatch();
  const eventUuid = useSelector(
    (state: State) => state.event.activeEvent?.event?.uuid
  );
  const {
    results: eventLiveImages,
    next,
    previous,
    count
  } = useSelector(
    (state: State) => state.event.activeEvent?.images?.liveImages
  );

  const fetchUrl = useMemo(() => {
    if (!defaultOffset) {
      if (next) {
        const offset = new URL(next).searchParams.get('offset');
        return API_CONFIG.PATH.EVENTS.GET_EVENT_IMAGES(
          eventUuid as string,
          offset as unknown as number
        );
      } else if (previous) {
        return API_CONFIG.PATH.EVENTS.GET_EVENT_IMAGES(
          eventUuid as string,
          count
        );
      }
    }
    return API_CONFIG.PATH.EVENTS.GET_EVENT_IMAGES(
      eventUuid as string,
      defaultOffset || 0
    );
  }, [count, defaultOffset, eventUuid, next, previous]);

  const { isLoading, mutate } = useSWR(
    fetchUrl,
    () => fetchEventLiveImages(fetchUrl, dispatch),
    {
      revalidateOnMount: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false
    }
  );

  return useMemo(
    () => ({
      eventUuid,
      eventLiveImages,
      isLoading,
      mutateEventLiveImages: mutate
    }),
    [isLoading, eventLiveImages, mutate, eventUuid]
  );
}

export function useEventVideos(defaultOffset?: number) {
  const dispatch = useDispatch();
  const eventUuid = useSelector(
    (state: State) => state.event.activeEvent?.event?.uuid
  );

  const {
    results: eventVideos,
    next,
    previous,
    count
  } = useSelector(
    (state: State) => state.event.activeEvent?.videos?.liveVideos
  );

  const fetchUrl = useMemo(() => {
    if (!defaultOffset) {
      if (next) {
        const offset = new URL(next).searchParams.get('offset');
        return API_CONFIG.PATH.EVENTS.GET_EVENT_VIDEOS(
          eventUuid as string,
          offset as unknown as number
        );
      } else if (previous) {
        return API_CONFIG.PATH.EVENTS.GET_EVENT_VIDEOS(
          eventUuid as string,
          count
        );
      }
    }
    return API_CONFIG.PATH.EVENTS.GET_EVENT_VIDEOS(
      eventUuid as string,
      defaultOffset || 0
    );
  }, [count, defaultOffset, eventUuid, next, previous]);

  const { isLoading, mutate } = useSWR(
    fetchUrl,
    () => fetchEventVideos(fetchUrl, dispatch),
    {
      revalidateOnMount: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false
    }
  );

  return useMemo(
    () => ({
      eventUuid,
      eventVideos,
      isLoading,
      mutateEventVideos: mutate
    }),
    [isLoading, eventVideos, mutate, eventUuid]
  );
}
