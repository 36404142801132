import React, { useCallback, useMemo, useState } from 'react';
import {
  Box,
  Button,
  ImageList,
  ImageListItem,
  Skeleton,
  Stack,
  Typography
} from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { SET_EVENT_NEW_IMAGES } from '../../../../store/actions/eventActions';
import { useEventLiveImages } from '../hooks';
import { useScreenSizes } from '../../../../shared/hooks/screenSizes';
import { IEventLiveImage } from '../../../../shared/interface';
import ImagesVideoWorkspace from '../workspaces/images-video-workspace';
import EventWorkSpace from '../workspaces/content-view-drawer';
import httpService from '../../../../shared/services/http.service';
import { API_CONFIG } from '../../../../shared/constants/constants';

const EventPhotos: React.FC = () => {
  const dispatch = useDispatch();
  const { eventUuid, eventLiveImages, isLoading, mutateEventLiveImages } =
    useEventLiveImages();
  const { isMd, isSm, isLg } = useScreenSizes();
  const [selectedImage, setSelectedImage] = useState<IEventLiveImage | null>(
    null
  );

  const imageCols = useMemo(() => {
    if (isLg) return 5;
    if (isMd) return 4;
    if (isSm) return 3;
    return 1;
  }, [isLg, isMd, isSm]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const newImages = acceptedFiles.map((file, index) => ({
        file,
        name: file.name,
        preview: URL.createObjectURL(file),
        id: `${eventUuid}-video--${index}`
      }));
      dispatch({
        type: SET_EVENT_NEW_IMAGES,
        payload: { items: newImages }
      });
    },
    [dispatch, eventUuid]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { 'image/png': ['.png'], 'image/jpeg': ['.jpeg', '.jpg'] },
    multiple: true,
    noClick: true
  });

  const {
    getRootProps: getRootPropsButton,
    getInputProps: getInputPropsButton
  } = useDropzone({
    onDrop,
    accept: { 'image/png': ['.png'], 'image/jpeg': ['.jpeg', '.jpg'] },
    multiple: true,
    noDrag: true
  });

  const handleDeleteImage = async () => {
    if (!(eventUuid && selectedImage)) return;
    try {
      await httpService.del(
        API_CONFIG.PATH.EVENTS.DELETE_EVENT_IMAGE(
          eventUuid,
          selectedImage?.uuid
        )
      )(dispatch, true);
      await mutateEventLiveImages(0);
      setSelectedImage(null);
    } catch (error) {
      console.log('error deleting image', error);
    }
  };

  return (
    <Stack direction="column" sx={{ minHeight: 'inherit' }} {...getRootProps()}>
      <Box className="flex flex-row justify-end mt-2 mb-5">
        <Button
          variant="outlined"
          className="capitalize"
          {...getRootPropsButton()}
        >
          <input {...getInputPropsButton()} />
          Upload Photos
        </Button>
      </Box>

      {isLoading && !eventLiveImages.length ? (
        <ImageList cols={imageCols} rowHeight={164} variant="quilted">
          {[...Array(30)].map((_, index) => (
            <ImageListItem key={index}>
              <Skeleton variant="rectangular" width="100%" height={164} />
            </ImageListItem>
          ))}
        </ImageList>
      ) : (
        <ImageList cols={imageCols} rowHeight={164} variant="quilted">
          {eventLiveImages.map((item, index) => (
            <ImageListItem
              key={index}
              onClick={() => setSelectedImage(item)}
              sx={{ borderRadius: '5px', margin: 0.5 }}
            >
              <img
                src={item.image}
                alt={`event-pix-${index + 1}`}
                width={400}
                height={400}
                style={{ borderRadius: '5px' }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      )}

      {eventLiveImages.length === 0 && !isLoading && (
        <Typography
          variant="body1"
          className="my-auto mx-auto"
          color="text.disabled"
          align="center"
        >
          Drag and drop images here or click the button to upload.
        </Typography>
      )}
      <input {...getInputProps()} />
      {selectedImage ? (
        <EventWorkSpace
          canExpand
          content={
            <ImagesVideoWorkspace
              contentType="snap"
              content={selectedImage}
              onDelete={handleDeleteImage}
            />
          }
          title={selectedImage.image?.split('/').at(-1) || ''}
          handleCloseDrawer={() => {
            setSelectedImage(null);
          }}
        />
      ) : null}
    </Stack>
  );
};

export default EventPhotos;
