import React, { useCallback, useMemo } from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Stack,
  Typography
} from '@mui/material';
import { IEvent } from '../../interface';
import { PermMedia, VideoLibrary, Visibility } from '@mui/icons-material';
import { fShortenNumber } from '../../utils';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { SET_ACTIVE_EVENT } from '../../../store/actions/eventActions';

export const noEventCoverImageAvailableUrl =
  'https://via.placeholder.com/150?text=No+Image+Added';

interface IProps {
  event: IEvent;
}

const EventCard: React.FC<IProps> = ({ event }: IProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const eventStatus: {
    status: string;
    color: 'primary' | 'success' | 'error';
  } = useMemo(() => {
    if (event.status === 'INITIAL') {
      return {
        status: 'Upcoming',
        color: 'primary'
      };
    }
    if (event.status === 'STARTED') {
      return {
        status: 'Live Now',
        color: 'success'
      };
    }
    return {
      status: 'Ended',
      color: 'error'
    };
  }, [event]);

  const handleViewDetails = useCallback(() => {
    if (event) {
      dispatch({
        type: SET_ACTIVE_EVENT,
        payload: { event }
      });
      navigate(`/dashboard/event/${event.uuid}`);
    }
  }, [dispatch, navigate, event]);

  const formattedDate = useMemo(() => {
    const date = new Date(event.event_date);
    return new Intl.DateTimeFormat('en-US', {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    }).format(date);
  }, [event.event_date]);

  return (
    <Card sx={{ minWidth: 275 }} className="flex flex-col">
      <CardMedia
        component="img"
        image={event.image || noEventCoverImageAvailableUrl}
        sx={{ height: 150, objectFit: 'cover' }}
        alt="Event Image"
      />
      <CardContent className="pb-1">
        <Typography
          className="text-nowrap pr-2 overflow-x-hidden text-ellipsis"
          variant="h6"
          color="text.secondary"
          gutterBottom
        >
          {event.name}
        </Typography>
        <Stack
          direction="row"
          className="d-flex flex-row flex-nowrap align-middle justify-between"
        >
          <Typography variant="subtitle1">{event.event_type.name}</Typography>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            {event.is_public ? 'Public' : 'Private'}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          className="my-2 d-flex flex-row align-middle justify-between"
        >
          <Typography variant="body2" color="text.disabled" gutterBottom>
            {formattedDate}
          </Typography>
          <Chip
            label={eventStatus.status}
            color={eventStatus.color}
            variant="outlined"
            size="small"
          />
        </Stack>
        <Stack
          direction="row"
          className="d-flex flex-row flex-nowrap align-middle justify-between"
        >
          <Typography variant="body2" color="text.secondary" gutterBottom>
            <PermMedia sx={{ mr: 1 }} />
            {fShortenNumber(event.num_live_images)} Photos
          </Typography>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            <Visibility sx={{ mr: 1 }} />
            {fShortenNumber(event.views_count || 0)} Views
          </Typography>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            <VideoLibrary sx={{ mr: 1 }} /> 0 Videos
          </Typography>
        </Stack>
      </CardContent>
      <CardActions className="mt-auto">
        <Button
          size="small"
          variant="contained"
          className="capitalize"
          fullWidth
          onClick={handleViewDetails}
        >
          Details
        </Button>
      </CardActions>
    </Card>
  );
};

export default EventCard;
