import React, { useMemo, useState } from 'react';
import { Badge, Box, Tab, Tabs } from '@mui/material';
import { Link, Outlet, useLocation } from 'react-router-dom';
import EventActionMenu from './workspaces/action-menu';
import EventImageUploadStatus from './workspaces/upload-staus';
import EventWorkSpace from './workspaces/content-view-drawer';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../../shared/interface';
import EventDetailsWorkspace from './workspaces/event-details-workspace';
import { SET_ACTIVE_EVENT_WORKSPACES } from '../../../store/actions/eventActions';

const EventDetails: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { openWorkspace, event: eventDetails } = useSelector(
    (state: State) => state.event.activeEvent
  );

  const selectedTab = useMemo(() => {
    if (location.pathname.includes('photos')) return 0;
    if (location.pathname.includes('videos')) return 1;
    if (location.pathname.includes('stream')) return 2;
    return 0;
  }, [location.pathname]);

  const [photosCount] = useState(0);
  const [videosCount] = useState(0);
  const [liveStreamCount] = useState(0);

  const uploadContentType = useMemo(() => {
    if (selectedTab === 0) return 'Images';
    if (selectedTab === 1) return 'Videos';
    return 'Images';
  }, [selectedTab]);

  return (
    <>
      <Tabs value={selectedTab}>
        <Tab
          label={
            <Badge badgeContent={photosCount} color="primary">
              Photos
            </Badge>
          }
          component={Link}
          to="photos"
        />
        <Tab
          label={
            <Badge badgeContent={videosCount} color="primary">
              Videos
            </Badge>
          }
          component={Link}
          to="videos"
        />
        <Tab
          label={
            <Badge badgeContent={liveStreamCount} color="primary">
              Live Stream
            </Badge>
          }
          component={Link}
          to="stream"
        />
      </Tabs>
      <Box sx={{ p: 4, pt: 0, mr: 2, minHeight: '70vh', position: 'relative' }}>
        <Outlet />
      </Box>
      <EventActionMenu />
      <EventImageUploadStatus contentType={uploadContentType} />
      {openWorkspace === 'event-details' && eventDetails ? (
        <EventWorkSpace
          canExpand
          title={eventDetails.name}
          backGroundColor="#F0F0F0"
          content={<EventDetailsWorkspace />}
          handleCloseDrawer={() => {
            dispatch({ type: SET_ACTIVE_EVENT_WORKSPACES, payload: null });
          }}
        />
      ) : null}
    </>
  );
};

export default EventDetails;
