import { Dispatch } from 'redux';
import httpService from '../../shared/services/http.service';
import { API_CONFIG } from '../../shared/constants/constants';

export const SET_TOKENS = 'SET_TOKENS';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const SET_COMPANY_DETAILS = 'SET_COMPANY_DETAILS';
export const SET_COMPANY_BALANCES = 'SET_COMPANY_BALANCES';
export const SET_USER_CLIENT_SECRET = 'SET_USER_CLIENT_SECRET';
export const SET_USER_SUBSCRIPTION_COMPLETE = 'SET_USER_SUBSCRIPTION_COMPLETE';

export const setTokens = (tokens: any) => ({
  type: SET_TOKENS,
  payload: tokens
});

export const setAuthenticated = (profile: any) => ({
  type: LOGIN_SUCCESS,
  payload: profile
});

export const fetchCompanyDetails = async (
  dispatch?: Dispatch,
  showLoader = false
) => {
  return await httpService
    .get(API_CONFIG.PATH.COMPANY.GET)(dispatch, showLoader)
    .then(data => {
      if (dispatch) {
        dispatch({
          type: SET_COMPANY_DETAILS,
          payload: data
        });
      }
      return data;
    });
};
