/* eslint-disable max-len */
import React from 'react';
import {
  Card,
  CardContent,
  Container,
  List,
  ListItem,
  ListItemText,
  Typography
} from '@mui/material';

function PrivacyPolicy() {
  return (
    <Container sx={{ pb: 4 }}>
      <Card sx={{ my: 4, px: 4 }}>
        <CardContent>
          <Typography variant="h5" component="h2">
            Privacy Policy Effective Date: 1st January 2023
          </Typography>

          <div className="mt-4">
            <Typography variant="h6" component="h3">
              1. Introduction
            </Typography>
            <Typography className="pt-4">
              Welcome to Pixsar Media, an application developed by Pixsar Media
              Limited. This Privacy Policy outlines how we collect, use,
              disclose, and safeguard your personal information when you use our
              application. By using Pixsar Media, you agree to the practices
              described in this policy.
            </Typography>
          </div>

          <div className="mt-4">
            <Typography variant="h6" component="h3">
              2. Information We Collect
            </Typography>
            <Typography className="pt-4">
              We may collect various types of information, including:
            </Typography>
            <List className="pl-5 pt-2">
              <ListItem>
                <ListItemText primary="Personal Information: Such as name, email address, and contact information, provided when you register or use certain features." />
              </ListItem>
              <ListItem>
                <ListItemText primary="Event Data: Photos, videos, live streams, and other content you upload to the application." />
              </ListItem>
              <ListItem>
                <ListItemText primary="Usage Data: Information about how you interact with the application, including browsing and usage patterns." />
              </ListItem>
              <ListItem>
                <ListItemText primary="Device Information: Device type, operating system, unique device identifiers, IP address, and mobile network information." />
              </ListItem>
              <ListItem>
                <ListItemText primary="Location Data: If you grant permission, we may collect and process location information to provide location-based services." />
              </ListItem>
            </List>
          </div>

          <div className="mt-4">
            <Typography variant="h6" component="h3">
              3. How We Use Your Information
            </Typography>
            <Typography className="pt-4">
              We use collected information for various purposes, including:
            </Typography>
            <List className="pt-2 pl-5">
              <ListItem>
                <ListItemText primary="Providing, maintaining, and improving our application's features and services." />
              </ListItem>
              <ListItem>
                <ListItemText primary="Personalizing your experience, offering content tailored to your preferences." />
              </ListItem>
              <ListItem>
                <ListItemText primary="Processing transactions and sending transaction-related communications." />
              </ListItem>
              <ListItem>
                <ListItemText primary="Sending updates, promotional materials, and other communications." />
              </ListItem>
              <ListItem>
                <ListItemText primary="Analyzing usage patterns to enhance our services." />
              </ListItem>
            </List>
          </div>

          <div className="mt-4">
            <Typography variant="h6" component="h3">
              4. Sharing of Information
            </Typography>
            <Typography className="pt-4">
              We may share your information with:
            </Typography>
            <List className="pl-5 pt-2">
              <ListItem>
                <ListItemText primary="Third-party service providers assisting us in operating our application and providing services." />
              </ListItem>
              <ListItem>
                <ListItemText primary="Other users, as required to facilitate event participation and sharing." />
              </ListItem>
              <ListItem>
                <ListItemText primary="Legal authorities if required by law or to protect our rights and safety." />
              </ListItem>
            </List>
          </div>

          <div className="mt-4">
            <Typography variant="h6" component="h3">
              5. Your Choices
            </Typography>
            <Typography className="pt-4">You can:</Typography>
            <List className="pl-5 pt-2">
              <ListItem>
                <ListItemText primary="Review, update, or delete your personal information through your account settings." />
              </ListItem>
              <ListItem>
                <ListItemText primary="Opt-out of certain communications." />
              </ListItem>
              <ListItem>
                <ListItemText primary="Control location sharing through your device settings." />
              </ListItem>
            </List>
          </div>

          <div className="mt-4">
            <Typography variant="h6" component="h3">
              6. Data Security
            </Typography>
            <Typography className="pt-4">
              We implement security measures to protect your data, but no method
              is completely secure. Please understand that transmission and
              storage of data is at your own risk.
            </Typography>
          </div>

          <div className="mt-4">
            <Typography variant="h6" component="h3">
              7. Children&apos;s Privacy
            </Typography>
            <Typography className="pt-4">
              Pixsar Media is not intended for children under 18. We do not
              knowingly collect personal information from children.
            </Typography>
          </div>

          <div className="mt-4">
            <Typography variant="h6" component="h3">
              8. Changes to this Privacy Policy
            </Typography>
            <Typography className="pt-4">
              We may update this Privacy Policy to reflect changes in our
              practices or legal requirements. Check for updates periodically.
            </Typography>
          </div>

          <div className="mt-4">
            <Typography variant="h6" component="h3">
              9. Contact Us
            </Typography>
            <Typography className="pt-4">
              For questions or concerns about this Privacy Policy, please
              contact us at:
            </Typography>
            <List className="pt-2 pl-5">
              <ListItem>
                <ListItemText
                  primary={
                    <a
                      className="text-decoration-none"
                      href="tel:+256200912077"
                    >
                      +256200912077
                    </a>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <a
                      className="text-decoration-none"
                      href="mailto:info@pixsar.io"
                      target="_blank"
                      rel="noreferrer"
                    >
                      info@pixsar.io
                    </a>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <a
                      className="text-decoration-none"
                      href="https://pixsar.io"
                    >
                      pixsar.io
                    </a>
                  }
                />
              </ListItem>
            </List>
          </div>
        </CardContent>
      </Card>
    </Container>
  );
}

export default PrivacyPolicy;
