import React, { useEffect } from 'react';
import { Button, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../interface';
import { API_CONFIG } from '../../constants/constants';
import { SET_COMPANY_BALANCES } from '../../../store/actions/authActions';

const WalletBalance: React.FC = () => {
  const dispatch = useDispatch();
  const { company } = useSelector((state: State) => state.auth.user);

  useEffect(() => {
    if (!company.uuid) {
      return;
    }

    const URL = `${API_CONFIG.BASE_URL.replace('api', '').replace(
      'https',
      'wss'
    )}${API_CONFIG.PATH.COMPANY.BALANCE(company.uuid)}`;

    const socket = new WebSocket(URL);

    socket.onmessage = (event: { data: string }) => {
      dispatch({
        type: SET_COMPANY_BALANCES,
        payload: JSON.parse(event.data)
      });
    };

    return () => {
      socket.close();
    };
  }, [dispatch, company.uuid]);

  if (!company.has_active_subscription || !company.balances) return null;

  return (
    <Stack>
      <Button
        disableRipple
        disableFocusRipple
        variant="text"
        className="capitalize mr-2"
        size="small"
      >
        Wallet: &nbsp;
        <span
          className="font-bold"
          style={{ fontFamily: 'Roboto Mono, sans-serif' }}
        >
          {`${company.balances.currency} ${Number(
            company.balances.total_balance
          ).toLocaleString()}`}
        </span>
      </Button>
    </Stack>
  );
};

WalletBalance.propTypes = {};

export default WalletBalance;
