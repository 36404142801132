import React from 'react';
import { Button, Stack } from '@mui/material';
import { useSelector } from 'react-redux';
import { Add as AddIcon } from '@material-ui/icons';
import { State } from '../../interface';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../../router/routes';

const CreateEventBtn: React.FC = () => {
  const navigate = useNavigate();
  const user = useSelector((state: State) => state.auth.user);

  if (!user.hasCompany || !user.company.has_active_subscription) return null;

  return (
    <Stack>
      <Button
        variant="outlined"
        className="capitalize"
        disableElevation
        size="small"
        startIcon={<AddIcon />}
        onClick={() => navigate(PATHS.createEvent)}
      >
        Create Event
      </Button>
    </Stack>
  );
};

CreateEventBtn.propTypes = {};

export default CreateEventBtn;
