import httpService from '../../shared/services/http.service';
import { Dispatch } from 'redux';
import actionTypes from '../action-types';
import { API_CONFIG } from '../../shared/constants/constants';

export const SET_ACTIVE_EVENT = 'SET_ACTIVE_EVENT';
export const SET_ACTIVE_EVENT_WORKSPACES = 'SET_ACTIVE_EVENT_WORKSPACES';
export const SET_EVENT_LIVE_IMAGES = 'SET_EVENT_LIVE_IMAGES';
export const SET_EVENT_VIDEOS = 'SET_EVENT_VIDEOS';
export const SET_EVENT_NEW_VIDEOS = 'SET_EVENT_NEW_VIDEOS';
export const SET_EVENT_NEW_IMAGES = 'SET_EVENT_NEW_IMAGES';
export const UPDATE_EVENT_NEW_VIDEOS = 'UPDATE_EVENT_NEW_VIDEOS';
export const UPDATE_EVENT_NEW_IMAGES = 'UPDATE_EVENT_NEW_IMAGES';
export const SET_EVENT_STREAM_DETAILS = 'SET_EVENT_STREAM_DETAILS';

export const fetchEvents = async (
  dispatch?: Dispatch,
  URL: string = API_CONFIG.PATH.EVENTS.GET,
  showLoader = false
) => {
  return await httpService
    .get(URL)(dispatch, showLoader)
    .then(data => {
      if (dispatch) {
        dispatch({
          type: actionTypes.FETCH_EVENTS_SUCCESS,
          payload: data
        });
      }
      return data;
    });
};

export const fetchEventLiveImages = async (
  url: string,
  dispatch?: Dispatch,
  showLoader = false
) => {
  return await httpService
    .get(url)(dispatch, showLoader)
    .then(data => {
      if (dispatch) {
        dispatch({
          type: SET_EVENT_LIVE_IMAGES,
          payload: data
        });
      }
      return data;
    });
};

export const fetchEventVideos = async (
  url: string,
  dispatch?: Dispatch,
  showLoader = false
) => {
  return await httpService
    .get(url)(dispatch, showLoader)
    .then(data => {
      if (dispatch) {
        dispatch({
          type: SET_EVENT_VIDEOS,
          payload: data
        });
      }
      return data;
    });
};

export const fetchEventsTypes = async (
  dispatch?: Dispatch,
  showLoader = false
) => {
  return await httpService
    .get(API_CONFIG.PATH.EVENTS.TYPES)(dispatch, showLoader)
    .then(data => data);
};

export const fetchStream = async (
  uuid: string,
  dispatch?: Dispatch,
  showLoader = false
) => {
  return await httpService
    .get(API_CONFIG.PATH.EVENTS.GET_EVENT_STREAM(uuid))(dispatch, showLoader)
    .then(data => {
      if (dispatch) {
        dispatch({
          type: SET_EVENT_STREAM_DETAILS,
          payload: data
        });
      }
      return data;
    });
};
