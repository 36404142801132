import { useMediaQuery } from '@mui/material';
import { useMemo } from 'react';
import { useTheme } from '@mui/material/styles';

export function useScreenSizes() {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.up('sm'));
  const isMd = useMediaQuery(theme.breakpoints.up('md'));
  const isLg = useMediaQuery(theme.breakpoints.up('lg'));
  const isMobile = useMediaQuery(theme.breakpoints.up('xs'));

  return useMemo(
    () => ({
      isSm,
      isMd,
      isLg,
      isMobile
    }),
    [isLg, isMd, isSm, isMobile]
  );
}
