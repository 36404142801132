import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles(theme => ({
  card: {
    borderRadius: theme.spacing(2),
    padding: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      width: '75%',
      margin: 'auto'
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  title: {
    color: '#333',
    marginBottom: theme.spacing(2)
  },
  listItem: {
    marginTop: theme.spacing(1)
  },
  bold: {
    fontWeight: 'bold'
  }
}));

const RemoveAccount = () => {
  const classes = useStyles();
  const appName = 'Pixsar Media'; // Define the app name

  return (
    <Container
      style={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
      }}
    >
      <Card className={classes.card}>
        <CardContent>
          <Typography variant="h4" component="h2" className={classes.title}>
            {appName} Account Deletion
          </Typography>
          <Typography variant="body1">
            Thank you for using {appName}. If you wish to delete your account
            and associated data, please follow the steps below:
          </Typography>
          <List>
            <ListItem className={classes.listItem}>
              <ListItemText
                primary={`Open the ${appName} app on your device`}
              />
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemText primary="Navigate to the account settings section." />
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemText primary='Click on "Delete Account" and confirm your choice.' />
            </ListItem>
          </List>
          <Typography
            variant="body1"
            className={`${classes.listItem} ${classes.bold}`}
          >
            Types of Data Deleted:
          </Typography>
          <List>
            <ListItem className={classes.listItem}>
              <ListItemText primary="All events attached to your phone number" />
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemText primary="User profile data" />
            </ListItem>
          </List>
          <Typography
            variant="body1"
            className={`${classes.listItem} ${classes.bold}`}
          >
            Data Retention:
          </Typography>
          <Typography variant="body1" className={classes.listItem}>
            None of your data will be retained, and this action is not
            reversible.
          </Typography>
        </CardContent>
      </Card>
    </Container>
  );
};

export default RemoveAccount;
