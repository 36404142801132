import {
  applyMiddleware,
  legacy_createStore as createStore,
  Store,
  UnknownAction
} from 'redux';
import { thunk } from 'redux-thunk';
import rootReducer from './store/reducer';
import { composeWithDevTools } from '@redux-devtools/extension';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
  key: 'pixsar-dashboard-v1',
  storage
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export function initializeStore(initialState = {}) {
  const store = createStore(
    persistedReducer,
    composeWithDevTools(applyMiddleware(thunk))
  );
  return {
    store,
    persist: persistStore(
      store as unknown as Store<any, UnknownAction, unknown>
    )
  };
}
