import React, { useState } from 'react';
import { Box, Button, Skeleton, Stack, Typography } from '@mui/material';
import 'react-phone-input-2/lib/style.css';
import {
  PaymentElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';
import { SET_USER_CLIENT_SECRET } from '../../store/actions/authActions';
import { useDispatch } from 'react-redux';

const CheckoutForm: React.FC = () => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleConfirmPayment = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/subscriptions?completed=true`
      }
    });

    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message || 'Am error occurred');
    } else {
      setMessage('An unexpected error occurred.');
    }

    setIsLoading(false);
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50 p-4">
      <Typography variant="h4" className="">
        Confirm Payment
      </Typography>
      <Box className="p-8">
        <PaymentElement />
        <i className="text-red w-1/3">{message}</i>

        {stripe && elements ? (
          <Stack className="mt-5 flex flex-row justify-between">
            <Button
              variant="outlined"
              size="small"
              className="capitalize"
              onClick={() => {
                dispatch({
                  type: SET_USER_CLIENT_SECRET,
                  payload: null
                });
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="small"
              className="capitalize"
              disabled={!stripe || !elements || isLoading}
              onClick={handleConfirmPayment}
            >
              Pay Now
            </Button>
          </Stack>
        ) : (
          <Stack className="mt-4 flex flex-row justify-between">
            <Skeleton variant="rectangular" width={80} height={36} />
            <Skeleton variant="rectangular" width={80} height={36} />
          </Stack>
        )}
      </Box>
    </div>
  );
};

export default CheckoutForm;
