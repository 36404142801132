import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/styles/index.css';
import { initializeStore } from './store';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import {
  ThemeProvider as StyledComponentsThemeProvider,
  ThemeProviderProps
} from 'styled-components';
import PageLoader from './shared/containers/loader';
import Notification from './shared/containers/notification';
import ErrorBoundary from './shared/components/errorBoundary';
import AppTheme from './theme';
import {
  StyledEngineProvider,
  ThemeProvider as MuiThemeProvider
} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { AppRoutes } from './router/routes';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { APP_CLIENT_ID } from './shared/constants/constants';
import { PersistGate } from 'redux-persist/integration/react';
// import './i18n/config'

const ThemeProvider = (
  props: ThemeProviderProps<any, any>
): React.JSX.Element => {
  return new StyledComponentsThemeProvider(
    props
  ) as unknown as React.JSX.Element;
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <StyledEngineProvider>
      <MuiThemeProvider theme={AppTheme}>
        <ThemeProvider theme={AppTheme}>
          <GoogleOAuthProvider clientId={APP_CLIENT_ID}>
            <Provider store={initializeStore().store}>
              <PersistGate loading={null} persistor={initializeStore().persist}>
                <CssBaseline />
                <ErrorBoundary>
                  <Notification />
                  <PageLoader />
                  <AppRoutes />
                </ErrorBoundary>
              </PersistGate>
            </Provider>
          </GoogleOAuthProvider>
        </ThemeProvider>
      </MuiThemeProvider>
    </StyledEngineProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
