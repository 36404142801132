import React from 'react';
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes
} from 'react-router-dom';
import NotFound from '../shared/components/errors/404';

import SignUp from '../pages/auth/signUp';
import Layout from '../layouts/main';
import SignIn from '../pages/auth/signIn';
import Dashboard from '../pages/dashboard';
import AuthLayout from '../layouts/auth/Index';

import CompanyProfile from '../pages/settings/index';
import EventDetails from '../pages/dashboard/event';
import PrivacyPolicy from '../pages/privacy-policy';
import RemoveAccount from '../pages/removeAccount';
import CreateEventPage from '../pages/dashboard/event/create';
import Subscriptions from '../pages/subscriptions';
import EventPhotos from '../pages/dashboard/event/photos';
import EventVideos from '../pages/dashboard/event/videos';
import StreamComponent from '../pages/dashboard/event/stream';

export const PATHS = {
  auth: {
    signIn: '/auth',
    signup: '/auth/signup'
  },
  dashboard: '/dashboard',
  createEvent: '/dashboard/event/create',
  event: (uuid: string) => `/dashboard/event/${uuid}`,
  settings: '/settings'
};

export const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route
          path="/*"
          element={
            <Layout>
              <Routes>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route
                  path="/dashboard/event/create"
                  element={<CreateEventPage />}
                />
                <Route path="/dashboard/event/:uud" element={<EventDetails />}>
                  <Route index element={<EventPhotos />} />
                  <Route path="photos" element={<EventPhotos />} />
                  <Route path="videos" element={<EventVideos />} />
                  <Route path="stream" element={<StreamComponent />} />
                </Route>
                <Route path="/settings" element={<CompanyProfile />} />
                <Route path="/subscriptions" element={<Subscriptions />} />
                <Route path="/" element={<Navigate to="/dashboard" />} />
              </Routes>
            </Layout>
          }
        />
        <Route
          path="/auth/*"
          element={
            <AuthLayout>
              <Routes>
                <Route path="/" element={<SignIn />} />
                <Route path="/signup" element={<SignUp />} />
              </Routes>
            </AuthLayout>
          }
        />
        <Route path="*" element={<NotFound />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/remove_account" element={<RemoveAccount />} />
      </Routes>
    </Router>
  );
};
