import React from 'react';
import {
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  Paper,
  Skeleton
} from '@mui/material';
import ContentCopy from '@mui/icons-material/ContentCopy';

const StreamSetupLoader = () => {
  return (
    <Box sx={{ padding: 2 }}>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
      >
        <Grid item>
          <Skeleton variant="text" width={150} height={40} />
        </Grid>
        <Grid item>
          <Skeleton variant="rectangular" width={100} height={40} />
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ marginTop: 2 }}>
        <Grid item xs={12} md={8}>
          <Paper sx={{ padding: 2, marginBottom: 2 }}>
            <Skeleton variant="rectangular" width="100%" height={300} />
          </Paper>
          <Paper sx={{ padding: 2 }}>
            <Skeleton variant="text" width={100} height={30} />
            <Box sx={{ maxHeight: 200, overflowY: 'auto', marginTop: 1 }}>
              <Skeleton variant="text" width="80%" />
              <Skeleton variant="text" width="60%" />
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper sx={{ padding: 2, marginBottom: 2 }}>
            <Skeleton variant="text" width={100} height={30} />
            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 1 }}>
              <Skeleton variant="rectangular" width="100%" height={56} />
              <IconButton disabled edge="end">
                <ContentCopy />
              </IconButton>
            </Box>
          </Paper>
          <Paper sx={{ padding: 2 }}>
            <Skeleton variant="text" width={100} height={30} />
            <List
              component="ol"
              sx={{ listStyleType: 'decimal', paddingLeft: 4 }}
            >
              <ListItem component="li" sx={{ display: 'list-item' }}>
                <Skeleton variant="text" width="90%" />
              </ListItem>
              <ListItem component="li" sx={{ display: 'list-item' }}>
                <Skeleton variant="text" width="80%" />
              </ListItem>
              <ListItem component="li" sx={{ display: 'list-item' }}>
                <Skeleton variant="text" width="85%" />
              </ListItem>
              <ListItem component="li" sx={{ display: 'list-item' }}>
                <Skeleton variant="text" width="70%" />
              </ListItem>
              <ListItem component="li" sx={{ display: 'list-item' }}>
                <Skeleton variant="text" width="75%" />
              </ListItem>
              <ListItem component="li" sx={{ display: 'list-item' }}>
                <Skeleton variant="text" width="80%" />
              </ListItem>
            </List>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default StreamSetupLoader;
