import React, { ReactElement } from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  styled
} from '@mui/material';
import {
  SettingsSuggest as DetailsIcon,
  SvgIconComponent
} from '@mui/icons-material';
import MuiDrawer from '@mui/material/Drawer';
import { useDispatch, useSelector } from 'react-redux';
import { EventWorkspaceTypes, State } from '../../../../shared/interface';
import { SET_ACTIVE_EVENT_WORKSPACES } from '../../../../store/actions/eventActions';

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open'
})(({ theme, open }) => ({
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  backgroundColor: 'white',
  color: '#260155',
  '& .MuiDrawer-paper': {
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(8)} + 1px)`
    },
    backgroundColor: 'white',
    color: '#260155'
  }
}));

interface SideMenuItem {
  path: string;
  icon: (color: string) => ReactElement<SvgIconComponent>;
  label: string;
  workspaceId: EventWorkspaceTypes;
}

const menuItems: SideMenuItem[] = [
  {
    path: '/settings',
    icon: color => <DetailsIcon sx={{ color }} />,
    label: 'Admin Settings',
    workspaceId: 'event-details'
  }
];

const EventActionMenu: React.FC = () => {
  const dispatch = useDispatch();
  const { openWorkspace } = useSelector(
    (state: State) => state.event.activeEvent
  );

  return (
    <Drawer variant="permanent" anchor="right" open={true}>
      <Box sx={{ height: '70px' }} />
      <List className="my-10">
        {menuItems.map(({ label, icon, path, workspaceId }, index) => (
          <ListItem
            key={workspaceId}
            disablePadding
            sx={{
              display: 'block',
              borderRight:
                workspaceId === openWorkspace ? '4px solid #260155' : 'none'
            }}
            component="div"
            onClick={() => {
              dispatch({
                type: SET_ACTIVE_EVENT_WORKSPACES,
                payload: workspaceId
              });
            }}
          >
            <ListItemButton
              selected={workspaceId === openWorkspace}
              sx={{
                minHeight: 48,
                justifyContent: 'center',
                px: 2.5
              }}
            >
              <ListItemIcon
                color="secondary"
                sx={{
                  minWidth: 0,
                  mr: 'auto',
                  justifyContent: 'center'
                }}
              >
                {icon(workspaceId === openWorkspace ? '#260155' : 'black')}
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};

EventActionMenu.propTypes = {};

export default EventActionMenu;
