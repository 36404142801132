import { UPDATE_PROFILE } from '../actions/profileActions';
import { Action, ProfileState } from '../../shared/interface';
import { Reducer } from 'redux';

const initialState: ProfileState = {
  profile: null
};

const profileReducer: Reducer<ProfileState, Action> = (
  state = initialState,
  action: Action
) => {
  switch (action.type) {
    case UPDATE_PROFILE:
      return {
        ...state,
        profile: action.payload
      };
    default:
      return state;
  }
};

export default profileReducer;
