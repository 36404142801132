import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  ImageList,
  ImageListItem,
  Skeleton,
  Stack,
  Typography
} from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { SET_EVENT_NEW_VIDEOS } from '../../../../store/actions/eventActions';
import { getVideoCover } from '../../../../shared/utils';
import { useEventVideos } from '../hooks';
import { IEventVideo } from '../../../../shared/interface';
import { useScreenSizes } from '../../../../shared/hooks/screenSizes';
import ImagesVideoWorkspace from '../workspaces/images-video-workspace';
import EventWorkSpace from '../workspaces/content-view-drawer';
import httpService from '../../../../shared/services/http.service';
import { API_CONFIG } from '../../../../shared/constants/constants';

async function createFileFromUrl(url: string) {
  try {
    // Fetch the file data from the URL
    const response = await fetch(url);
    const blob = await response.blob();

    // Extract the filename from the URL
    const filename = url.substring(url.lastIndexOf('/') + 1);

    // Create a File object
    const file = new File([blob], filename, { type: blob.type });

    return file;
  } catch (error) {
    console.error('Error creating file from URL:', error);
    return null;
  }
}

const VideoThumbnail = ({ video }: { video: IEventVideo }) => {
  const [thumbnail, setThumbnail] = useState(
    'https://d2uolguxr56s4e.cloudfront.net/img/kartrapages/video_player_placeholder.gif'
  );

  const generateThumbnail = useCallback(async () => {
    if (video.thumbnail) {
      setThumbnail(video.thumbnail);
      return;
    }
    try {
      const file = await createFileFromUrl(video.file);
      if (file) {
        const thumbnailUrl = await getVideoCover(file);
        setThumbnail(thumbnailUrl);
      }
    } catch (error) {
      console.error('Error generating thumbnail:', error);
    }
  }, [video]);

  useEffect(() => {
    generateThumbnail().then();
  }, [generateThumbnail]);

  return (
    <div
      style={{
        position: 'relative',
        borderRadius: '5px',
        width: '100%',
        height: '100%',
        backgroundColor: '#000',
        cursor: 'pointer'
      }}
    >
      {thumbnail ? (
        <img
          src={thumbnail}
          alt="Video Thumbnail"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            borderRadius: '5px'
          }}
        />
      ) : (
        <div
          style={{
            display: 'flex',
            borderRadius: '5px',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%'
          }}
        >
          <span style={{ color: '#fff', fontSize: '24px' }}>Loading...</span>
        </div>
      )}
      <div
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          borderRadius: '5px',
          top: '0',
          left: '0',
          backgroundColor: ' rgba(128, 128, 128, 0.5)'
        }}
      />
      <div
        style={{
          position: 'absolute',
          borderRadius: '5px',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        }}
      >
        <svg width="50" height="50" viewBox="0 0 24 24">
          <path fill="#fff" d="M8 5v14l11-7z" />
        </svg>
      </div>
    </div>
  );
};

const EventVideos: React.FC = () => {
  const dispatch = useDispatch();
  const { eventUuid, eventVideos, isLoading, mutateEventVideos } =
    useEventVideos();
  const { isMd, isSm, isLg } = useScreenSizes();
  const [selectedVideo, setSelectedVideo] = useState<IEventVideo | null>(null);

  const imageCols = useMemo(() => {
    if (isLg) return 5;
    if (isMd) return 4;
    if (isSm) return 3;
    return 1;
  }, [isLg, isMd, isSm]);

  const onDropFiles = useCallback(
    async (acceptedFiles: File[]) => {
      const newVideos = await Promise.all(
        acceptedFiles.map(async (file, index) => {
          try {
            const preview = await getVideoCover(file);
            return {
              file,
              name: file.name,
              preview,
              id: `${eventUuid}-video--${index}`
            };
          } catch (error) {
            console.error('Error processing video:', error);
            return null;
          }
        })
      );

      dispatch({
        type: SET_EVENT_NEW_VIDEOS,
        payload: { items: newVideos.filter(Boolean) }
      });
    },
    [dispatch, eventUuid]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDropFiles,
    // maxSize: 512000,
    accept: { 'video/*': [] },
    multiple: true,
    noClick: true
  });

  const {
    getRootProps: getRootPropsButton,
    getInputProps: getInputPropsButton
  } = useDropzone({
    onDrop: onDropFiles,
    // maxSize: 512000,
    accept: { 'video/*': [] },
    multiple: true,
    noDrag: true
  });

  const handleDeleteVideo = async () => {
    if (!(eventUuid && selectedVideo)) return;
    try {
      await httpService.del(
        API_CONFIG.PATH.EVENTS.DELETE_EVENT_VIDEO(
          eventUuid,
          selectedVideo?.uuid
        )
      )(dispatch, true);
      await mutateEventVideos(0);
      setSelectedVideo(null);
    } catch (error) {
      console.log('error deleting video', error);
    }
  };

  return (
    <Stack direction="column" sx={{ minHeight: 'inherit' }} {...getRootProps()}>
      <Box className="flex flex-row justify-end my-2">
        <Button
          variant="outlined"
          className="capitalize"
          {...getRootPropsButton()}
        >
          <input {...getInputPropsButton()} />
          Upload Videos
        </Button>
      </Box>
      {isLoading ? (
        <ImageList cols={imageCols} rowHeight={164} variant="quilted">
          {[...Array(16)].map((_, index) => (
            <ImageListItem key={index}>
              <Skeleton variant="rectangular" width="100%" height={164} />
            </ImageListItem>
          ))}
        </ImageList>
      ) : (
        <ImageList cols={imageCols} rowHeight={164} variant="quilted">
          {eventVideos.map((item, index) => (
            <ImageListItem
              key={index}
              onClick={() => setSelectedVideo(item)}
              sx={{ borderRadius: '5px', margin: 0.5 }}
            >
              <VideoThumbnail video={item} />
            </ImageListItem>
          ))}
        </ImageList>
      )}
      {eventVideos.length === 0 && !isLoading && (
        <Typography
          variant="body1"
          className="my-auto mx-auto"
          color="text.disabled"
          align="center"
        >
          Drag and drop videos here or click the button to upload.
        </Typography>
      )}
      <input {...getInputProps()} />
      {selectedVideo ? (
        <EventWorkSpace
          canExpand
          title={selectedVideo.file?.split('/').at(-1) || ''}
          content={
            <ImagesVideoWorkspace
              contentType="clip"
              content={selectedVideo}
              onDelete={handleDeleteVideo}
            />
          }
          handleCloseDrawer={() => {
            setSelectedVideo(null);
          }}
        />
      ) : null}
    </Stack>
  );
};

export default EventVideos;
