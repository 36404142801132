import React, { useMemo } from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import useSWR from 'swr';
import { fetchEvents } from '../../store/actions/eventActions';
import { useDispatch, useSelector } from 'react-redux';
import { API_CONFIG, swrOptions } from '../../shared/constants/constants';
import { State } from '../../shared/interface';
import EventCardSkeleton from '../../shared/components/event-card/event-loader';
import EventCard from '../../shared/components/event-card';
import CreateEventBtn from '../../shared/components/create-event-btn';

const Dashboard = () => {
  const dispatch = useDispatch();
  const {
    results: events,
    count,
    next
  } = useSelector((state: State) => state.event);
  const { company } = useSelector((state: State) => state.auth.user);

  const URL = useMemo(() => {
    if (!(company.uuid && company.has_active_subscription)) return undefined;
    const offset = next && count > events?.length ? events.length : null;
    return `${API_CONFIG.PATH.EVENTS.GET}?limit=10${
      offset ? `&offset=${offset}` : ''
    }`;
  }, [
    company.has_active_subscription,
    company.uuid,
    count,
    events.length,
    next
  ]);

  const { isLoading } = useSWR(URL, () => fetchEvents(dispatch, URL), {
    ...swrOptions,
    revalidateOnMount: true
  });

  const renderEventLoaders = () => {
    if (!isLoading) return;
    return Array.from({ length: 16 }).map((_, index) => (
      <Grid key={index} item xs={12} sm={6} md={4} lg={3} xl={3}>
        <EventCardSkeleton />
      </Grid>
    ));
  };

  return (
    <>
      <Grid container spacing={2}>
        {renderEventLoaders()}
        {!isLoading && events?.length === 0 ? (
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            className="w-full"
            style={{ height: '70vh' }}
          >
            <Typography className="mb-2">You have no events </Typography>
            <CreateEventBtn />
          </Stack>
        ) : null}
        {events?.map(event => (
          <Grid key={event.uuid} item xs={12} sm={6} md={4} lg={3}>
            <EventCard event={event} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default Dashboard;
