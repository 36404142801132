import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { IEvent, NotificationType, State } from '../../../../shared/interface';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import httpService from '../../../../shared/services/http.service';
import { API_CONFIG, swrOptions } from '../../../../shared/constants/constants';
import actionTypes from '../../../../store/action-types';
import {
  fetchEventsTypes,
  SET_ACTIVE_EVENT
} from '../../../../store/actions/eventActions';
import useSWR from 'swr';
import { currencyOptions, ICreateEventForm, validationSchema } from '../create';
import { noEventCoverImageAvailableUrl } from '../../../../shared/components/event-card';

const EventDetailsWorkspace: React.FC = () => {
  const { balances } = useSelector((state: State) => state.auth.user.company);
  const { event: eventDetails } = useSelector(
    (state: State) => state.event.activeEvent
  );
  const eventBalance = useMemo(() => {
    if (!eventDetails) return null;
    return (
      balances?.events.find(
        ({ event_uuid }) => event_uuid === eventDetails.uuid
      )?.wallet_balance || null
    );
  }, [balances, eventDetails]);

  const dispatch = useDispatch();
  const { data: eventTypes } = useSWR(
    API_CONFIG.PATH.EVENTS.TYPES,
    () => fetchEventsTypes(),
    swrOptions
  );

  const [coverImage, setCoverImage] = useState<
    string | ArrayBuffer | undefined
  >('');
  const [croppedImage, setCroppedImage] = useState<File | null>(null);
  const [photoUpdating, setPhotoUpdating] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const coverImageLabel = useMemo(
    () => `${eventDetails?.image ? 'Update' : 'Pick'} Cover Image`,
    [eventDetails?.image]
  );

  const coverImageUrl = useMemo(
    () =>
      coverImage?.toString() ||
      eventDetails?.image ||
      noEventCoverImageAvailableUrl,
    [coverImage, eventDetails?.image]
  );

  const eventAction = useMemo(() => {
    if (eventDetails?.status === 'STARTED') return 'Stop Event';
    if (eventDetails?.status === 'ENDED') return 'Start Event';
    return 'Start Event';
  }, [eventDetails?.status]);

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const fileTypes = ['image/jpg', 'image/png', 'image/jpeg'];
    const file = event.target.files?.[0];

    // Check if file is selected and its type is valid
    if (file && fileTypes.includes(file.type)) {
      // Check file size (900 KB = 900 * 1024 bytes)
      if (file.size <= 900 * 1024) {
        const imageDataUrl = await readFile(file);
        setCroppedImage(file as File);
        setCoverImage(imageDataUrl as string);
      } else {
        dispatch({
          type: actionTypes.SET_NOTIFICATION,
          payload: {
            message: 'Error: File size should be less than 500KB',
            type: NotificationType.ERROR
          }
        });
      }
    } else {
      dispatch({
        type: actionTypes.SET_NOTIFICATION,
        payload: {
          message: 'Error: Incorrect file type',
          type: NotificationType.ERROR
        }
      });
    }
  };

  const readFile = (file: File): Promise<any> => {
    setPhotoUpdating(true);
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setPhotoUpdating(false);
        resolve(reader.result);
      });
      reader.readAsDataURL(file);
    });
  };

  const handleFilePickerClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const formik = useFormik({
    initialValues: {
      name: eventDetails?.name || '',
      event_date: eventDetails?.event_date || '',
      event_type_uuid:
        eventDetails?.event_type.uuid || eventTypes?.results?.[0]?.uuid || '',
      is_private: !eventDetails?.is_public,
      is_premium: !!eventDetails?.is_premium,
      price: eventDetails?.price
        ? Number(eventDetails.price).toLocaleString()
        : '',
      currency: 'UGX'
    },
    validationSchema,
    onSubmit: async (values: ICreateEventForm) => {
      if (!eventDetails) return;
      try {
        const editEventFormData = new FormData();

        (Object.keys(values) as (keyof ICreateEventForm)[]).forEach(key => {
          if (values[key] !== undefined && values[key] !== null) {
            editEventFormData.set(key, String(values[key]));
            if (key === 'price' && values.is_premium) {
              editEventFormData.set(key, values.price.replaceAll(',', ''));
            }
          }
        });

        if (croppedImage) {
          editEventFormData.set('image', croppedImage);
        }

        const response: IEvent = await httpService.patch(
          API_CONFIG.PATH.EVENTS.EDIT(eventDetails.uuid),
          editEventFormData,
          {},
          { contentType: 'multipart/form-data' }
        )(dispatch, true);
        dispatch({
          type: actionTypes.SET_NOTIFICATION,
          payload: {
            message: `Event ${values.name} was updated successfully`,
            type: NotificationType.SUCCESS
          }
        });
        dispatch({
          type: SET_ACTIVE_EVENT,
          payload: { event: response }
        });
        formik.resetForm();
        setCoverImage(undefined);
      } catch (e) {
        console.log(e);
      }
    }
  });

  const handleStartStopEvent = async () => {
    if (!eventDetails?.uuid) return;
    try {
      await httpService.post(
        API_CONFIG.PATH.EVENTS.START_STOP_EVENT(eventDetails?.uuid),
        {
          action: eventDetails?.status === 'STARTED' ? 'end' : 'start'
        }
      )(dispatch, true);
      dispatch({
        type: actionTypes.SET_NOTIFICATION,
        payload: {
          message: `Event was ${
            eventDetails?.status === 'STARTED' ? 'ended' : 'started'
          } successfully`,
          type: NotificationType.SUCCESS
        }
      });

      dispatch({
        type: SET_ACTIVE_EVENT,
        payload: {
          event: {
            ...eventDetails,
            status: eventDetails?.status === 'STARTED' ? 'ENDED' : 'STARTED'
          }
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  const amountReceivable = useMemo(() => {
    if (formik.values.price && !formik.errors.price) {
      return `Amount after 24.5% deduction: ${balances?.currency} ${(
        Number(formik.values.price.replaceAll(/,/g, '')) * 0.755
      ).toLocaleString()}`;
    }
    return null;
  }, [formik.values.price, formik.errors.price]);

  const handlePriceChange = (event: any) => {
    const value = event.target.value.replace(/,/g, '');
    if (!isNaN(Number(value))) {
      formik.setFieldValue('price', Number(value).toLocaleString());
    }
  };

  useEffect(() => {
    if (eventTypes?.results?.[0]?.uuid && !formik.values.event_type_uuid) {
      formik.setFieldValue('event_type_uuid', eventTypes.results[0].uuid);
    }
  }, [eventTypes, formik]);

  if (!eventDetails) return null;

  return (
    <Stack
      sx={{ flexGrow: 1, padding: '10px' }}
      justifyContent="center"
      alignItems="center"
    >
      <Container className="h-full" maxWidth={false}>
        <Grid item xs={12} className="mb-3 flex justify-between items-center">
          {eventBalance ? (
            <Button
              disableRipple
              disableFocusRipple
              color="info"
              variant="text"
              className="capitalize"
              size="small"
            >
              Event Wallet: &nbsp;
              <span
                className="font-bold"
                style={{ fontFamily: 'Roboto Mono, sans-serif' }}
              >
                {`${balances?.currency} ${Number(
                  eventBalance
                ).toLocaleString()}`}
              </span>
            </Button>
          ) : null}
          <Button
            className="capitalize"
            variant="outlined"
            color="primary"
            type="submit"
            size="small"
            onClick={() => handleStartStopEvent()}
          >
            {eventAction}
          </Button>
        </Grid>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Box
                sx={{
                  height: 250,
                  width: 400,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  overflow: 'hidden'
                }}
              >
                <img
                  src={coverImageUrl}
                  alt="Cropped Cover"
                  style={{
                    height: '100%',
                    width: '100%',
                    objectFit: 'cover'
                  }}
                />
              </Box>
              <Box sx={{ mt: 3 }}>
                <Button
                  size="small"
                  variant="outlined"
                  className="capitalize"
                  onClick={handleFilePickerClick}
                  disabled={photoUpdating}
                >
                  {photoUpdating ? (
                    <CircularProgress size={24} />
                  ) : (
                    coverImageLabel
                  )}
                </Button>
                <br />
                <Typography variant="caption">
                  Image should not exceed 900KB
                </Typography>
                <input
                  type="file"
                  accept="image/*"
                  style={{ display: 'none' }}
                  ref={fileInputRef}
                  onChange={handleFileChange}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                variant="outlined"
                label="Event Name"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                variant="outlined"
                label="Event Date"
                type="date"
                name="event_date"
                value={formik.values.event_date}
                onChange={formik.handleChange}
                error={
                  formik.touched.event_date && Boolean(formik.errors.event_date)
                }
                helperText={
                  formik.touched.event_date && formik.errors.event_date
                }
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormLabel>Event Type</FormLabel>
              <Select
                fullWidth
                variant="outlined"
                label="Event Type"
                name="event_type_uuid"
                value={formik.values.event_type_uuid}
                onChange={formik.handleChange}
                error={
                  formik.touched.event_type_uuid &&
                  Boolean(formik.errors.event_type_uuid)
                }
              >
                {eventTypes &&
                  eventTypes.results.map((type: any) => (
                    <MenuItem key={type.uuid} value={type.uuid}>
                      {type.name}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="is_private"
                    color="primary"
                    checked={formik.values.is_private}
                    onChange={formik.handleChange}
                  />
                }
                label="Is Private"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="is_premium"
                    color="primary"
                    checked={formik.values.is_premium}
                    onChange={formik.handleChange}
                  />
                }
                label="Is Premium"
              />
            </Grid>
            {formik.values.is_premium && (
              <>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Price"
                    name="price"
                    value={formik.values.price}
                    onChange={handlePriceChange}
                    error={formik.touched.price && Boolean(formik.errors.price)}
                    helperText={formik.touched.price && formik.errors.price}
                  />
                  <Typography variant="caption" color="textSecondary">
                    {amountReceivable}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormLabel>Currency</FormLabel>
                  <Select
                    readOnly
                    fullWidth
                    variant="outlined"
                    label="Currency"
                    name="currency"
                    value={formik.values.currency}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.currency && Boolean(formik.errors.currency)
                    }
                  >
                    {currencyOptions.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </>
            )}
            <Grid item xs={12} className="flex justify-end items-center">
              <Button
                className="capitalize"
                variant="contained"
                color="primary"
                type="submit"
                size="small"
                disabled={!formik.dirty}
              >
                Update Event
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Stack>
  );
};

export default EventDetailsWorkspace;
