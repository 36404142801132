export interface ResponseObj<T> {
  error?: string;
  data?: T;
}

export interface Logins {
  email: string | null;
  password: string | null;
}

export interface ISubscriptionPackage {
  uuid: string;
  name: string;
  price: string;
  description: string;
  event_limit?: number;
  stream_duration_limit?: number;
}

export interface EventBalance {
  event_uuid: string;
  wallet_balance: number;
  event_name: string;
}

export interface CompanyBalance {
  total_balance: number;
  currency: string;
  events: EventBalance[];
}

export interface ICompany {
  uuid: string;
  name: string;
  currency: string;
  location: string;
  support_number: string;
  country: string;
  business_email: string;
  company_document_one: string | null;
  company_document_two: string | null;
  company_document_three: string | null;
  national_id: string | null;
  has_active_subscription: boolean;
  latest_subscription_package: ISubscriptionPackage | null;
  balances: CompanyBalance | null;
  logo: string | null;
  description: string | null;
}

export interface IUser {
  uuid: string;
  email: string;
  username: string;
  hasCompany: boolean;
  clientSecret: string;
  subscriptionComplete: false;
  authProvider: string;
  company: ICompany;
}

interface IEventType {
  uuid: string;
  name: string;
  icon: string | null;
}

export interface IEvent {
  uuid: string;
  name: string;
  event_live_images?: IEventLiveImage[];
  image: string | null;
  is_public: boolean;
  is_premium: boolean;
  price: number | null;
  event_type: IEventType;
  stream_link: string | null;
  vod_link: string | null;
  status: 'INITIAL' | 'STARTED' | 'ENDED';
  event_code: string;
  event_date: string;
  views_count: number;
  num_live_images: number;
  currency: string | null;
  app_comments: any[];
  company: {
    name: string | null;
    location: string | null;
    logo: string | null;
  };
  user_has_paid: boolean;
  user_has_subscribed: boolean;
}

export interface INewEventContent {
  file: File;
  name: string;
  preview: string;
  id: string;
}

export interface IEventLiveImage {
  uuid: string;
  caption: string | null;
  image: string;
  event: IEvent;
  viewsCount: number;
}

export interface IEventVideo {
  uuid: string;
  thumbnail: string | null;
  file: string;
}

export interface IEventStream {
  id: number;
  event: IEvent;
  name: string;
  enabled: boolean;
  start_time: string | null;
  end_time: string | null;
  hls_link: string;
  rtmp_link: string;
  is_running: boolean;
  running_time: string;
}

export * from './state';
