import React from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Skeleton,
  Stack
} from '@mui/material';

const EventCardSkeleton = () => {
  return (
    <Card sx={{ minWidth: 275 }}>
      <CardMedia>
        <Skeleton variant="rectangular" width="100%" height={150} />
      </CardMedia>
      <CardContent className="pb-1">
        <Skeleton variant="text" width="60%" />
        <Stack
          direction="row"
          className="d-flex flex-row flex-nowrap align-middle justify-between"
        >
          <Skeleton variant="text" width="40%" />
          <Skeleton variant="text" width="20%" />
        </Stack>
        <Stack
          direction="row"
          className="my-2 d-flex flex-row align-middle justify-between"
        >
          <Skeleton variant="text" width="60%" />
          <Skeleton variant="text" width="20%" />
        </Stack>
        <Stack
          direction="row"
          className="d-flex flex-row flex-nowrap align-middle justify-between"
        >
          <Skeleton variant="text" width="30%" />
          <Skeleton variant="text" width="30%" />
          <Skeleton variant="text" width="30%" />
        </Stack>
      </CardContent>
      <CardActions>
        <Button
          size="small"
          variant="contained"
          className="capitalize"
          fullWidth
          disabled
        >
          Details
        </Button>
      </CardActions>
    </Card>
  );
};

export default EventCardSkeleton;
