import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import {
  CSSObject,
  Divider,
  DrawerProps as MiuDrawerProps,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
  Theme
} from '@mui/material';
// eslint-disable-next-line max-len
import {
  Home as EventsIcon,
  Settings as SettingsIcon,
  Subscriptions,
  SvgIconComponent
} from '@mui/icons-material';
import MuiDrawer from '@mui/material/Drawer';
import { StyledImage } from '../../../layouts/auth/Index';
import AppLogo from '../../../assets/images/logo_white.svg';
import minimizedAppLogo from '../../../assets/images/standalone_logo.svg';
import { DrawerHeader, drawerWidth } from '../../../layouts/main';
import { useLocation, useNavigate } from 'react-router-dom';

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  }
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open'
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}));

interface IProps extends MiuDrawerProps {
  isDrawerOpen: boolean;
}

interface SideMenuItem {
  path: string;
  icon: (color: string) => ReactElement<SvgIconComponent>;
  label: string;
}

const menuItems: SideMenuItem[][] = [
  [
    {
      path: '/dashboard',
      icon: color => <EventsIcon sx={{ color }} />,
      label: 'Events'
    }
    // {
    //   path: '/live-stream',
    //   icon: color => <LiveStreamIcon sx={{ color }} />,
    //   label: 'Live Stream'
    // }
  ],
  [
    {
      path: '/settings',
      icon: color => <SettingsIcon sx={{ color }} />,
      label: 'Admin Settings'
    },
    {
      path: '/subscriptions',
      icon: color => <Subscriptions sx={{ color }} />,
      label: 'Subscriptions'
    }
  ]
];

const AppSideMenu: React.FC<IProps> = ({ isDrawerOpen }: IProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Drawer
      variant="permanent"
      open={isDrawerOpen}
      color="primary"
      PaperProps={{
        sx: {
          backgroundColor: '#260155',
          color: 'white'
        }
      }}
    >
      <DrawerHeader>
        <StyledImage
          src={isDrawerOpen ? AppLogo : minimizedAppLogo}
          className={isDrawerOpen ? 'ml-2' : ''}
        />
      </DrawerHeader>
      <List className="my-10">
        {menuItems.map((group, groupIndex) => (
          <React.Fragment key={`group-${groupIndex}`}>
            {group.map(({ label, icon, path }, index) => (
              <ListItem
                key={path}
                disablePadding
                sx={{
                  display: 'block',
                  backgroundColor: location.pathname.includes(path)
                    ? 'white'
                    : 'transparent'
                }}
                component="div"
              >
                <ListItemButton
                  onClick={() => navigate(path)}
                  sx={{
                    minHeight: 48,
                    justifyContent: isDrawerOpen ? 'initial' : 'center',
                    px: 2.5
                  }}
                >
                  <ListItemIcon
                    color="secondary"
                    sx={{
                      minWidth: 0,
                      mr: isDrawerOpen ? 3 : 'auto',
                      justifyContent: 'center'
                    }}
                  >
                    {icon(
                      location.pathname.includes(path) ? '#260155' : 'white'
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={label}
                    sx={{
                      opacity: isDrawerOpen ? 1 : 0,
                      color: location.pathname.includes(path)
                        ? '#260155'
                        : 'white'
                    }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
            {groupIndex < menuItems.length - 1 && (
              <Divider sx={{ borderColor: 'white', my: 5 }} />
            )}
          </React.Fragment>
        ))}
      </List>
    </Drawer>
  );
};

AppSideMenu.propTypes = {
  isDrawerOpen: PropTypes.bool.isRequired
};

export default AppSideMenu;
