import React, { useEffect, useState } from 'react';
import { Stripe } from '@stripe/stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../shared/interface';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  SET_USER_CLIENT_SECRET,
  SET_USER_SUBSCRIPTION_COMPLETE
} from '../../store/actions/authActions';
import { PATHS } from '../../router/routes';

function CompletionSubscription({ stripePromise }: { stripePromise: any }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { clientSecret } = useSelector((state: State) => state.auth.user);
  const [error, setError] = useState('');

  useEffect(() => {
    if (!stripePromise) return;

    stripePromise.then(async (stripe: Stripe) => {
      const { error: err } = await stripe.retrievePaymentIntent(clientSecret);

      if (err) {
        setError(err.message || 'Something went wrong');
      }
    });
  }, [clientSecret, stripePromise]);

  const handleContinue = () => {
    dispatch({
      type: SET_USER_CLIENT_SECRET,
      payload: null
    });
    dispatch({
      type: SET_USER_SUBSCRIPTION_COMPLETE,
      payload: false
    });
    navigate(PATHS.dashboard);
  };

  return (
    <>
      <div id="messages" role="alert">
        {error ? (
          error
        ) : (
          <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
            <div className="bg-white p-8 text-center">
              <h1 className="text-2xl font-bold mb-4">
                Thank You for Your Subscription!
              </h1>
              <p className="text-gray-600 mb-8">
                Your subscription has been successfully activated.
              </p>
              <Button
                variant="contained"
                color="primary"
                onClick={handleContinue}
                className="w-full capitalize"
              >
                Continue to Your Dashboard
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default CompletionSubscription;
