import {
  IEvent,
  IEventLiveImage,
  IEventStream,
  IEventVideo,
  INewEventContent,
  IUser
} from './index';

export interface LoadingState {
  api: {
    [key: string]: boolean;
  };
}

export enum NotificationType {
  SUCCESS = 'success',
  WARNING = 'warning',
  INFO = 'info',
  ERROR = 'error'
}

export interface NotificationStateProps {
  addNotification: (message: string, type: string) => void;
  resetNotification: () => void;
}

export interface LoaderState {
  success?: boolean;
  loading: boolean;
}

export interface NotificationState {
  message: string;
  type: NotificationType;
}

export interface CommonState {
  loader: LoaderState;
  notification: NotificationState;
}

export interface AuthState {
  isAuthenticated: boolean;
  user: IUser;
  tokens: {
    access: string;
    refresh: string;
  };
}

export interface ProfileState {
  profile: any | null;
}

export type EventWorkspaceTypes = 'event-details' | 'photo-editor';

export interface EventState {
  count: number;
  next: number | null;
  previous: number | null;
  results: IEvent[];
  activeEvent: {
    event: IEvent | null;
    stream: IEventStream | null;
    openWorkspace: EventWorkspaceTypes | null;
    images: {
      newImages: {
        count: number;
        uploaded: number;
        items: INewEventContent[];
      };
      liveImages: {
        count: number;
        next: string | null;
        previous: string | null;
        results: IEventLiveImage[];
      };
    };
    videos: {
      newVideos: {
        count: number;
        uploaded: number;
        items: INewEventContent[];
      };
      liveVideos: {
        count: number;
        next: string | null;
        previous: string | null;
        results: IEventVideo[];
      };
    };
  };
}

export interface Action {
  type: string;
  payload: any;
}

export interface State {
  loading: LoadingState;
  event: EventState;
  common: CommonState;
  auth: AuthState;
  profile: ProfileState;
}
