import 'numeral/locales/de';
import numeral from 'numeral';

numeral.locale('de');

export function titleCase(str?: string) {
  if (!str) {
    return str;
  }
  return str.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase());
}

export function camelToSnakeCase(str: string) {
  return str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
}

export function randomColor() {
  const color = Math.floor(Math.random() * 16777215).toString(16);
  return `#${color}`;
}

export function fNumber(number: number) {
  return numeral(number).format();
}

export function fCurrency(number: number) {
  const format: string = number ? numeral(number).format('$0,0.00') : '';
  return result(format, '.00');
}

export function fPercent(number: number) {
  if (number === 0) return '0%';
  const format: string = number
    ? numeral(Number(number) / 100).format('0%')
    : '';
  return result(format, '');
}

export function fShortenNumber(number: number) {
  if (number === 0) return number;
  const format: string = number ? numeral(number).format('a') : '';
  return result(format, '');
}

export function fData(number: number) {
  const format: string = number ? numeral(number).format('0.0 b') : '';
  return result(format, '.0');
}

function result(format: string, key = '.00') {
  const isInteger = format.includes(key);
  return isInteger ? format.replace(key, '') : format;
}

export async function getVideoCover(file: File, seekTo = 0.0): Promise<string> {
  return new Promise(resolve => {
    const videoPlayer = document.createElement('video');
    videoPlayer.src = URL.createObjectURL(file);
    videoPlayer.load();

    videoPlayer.addEventListener('error', () => {
      resolve(
        'https://d2uolguxr56s4e.cloudfront.net/img/kartrapages/video_player_placeholder.gif'
      );
    });

    videoPlayer.addEventListener('loadedmetadata', () => {
      if (videoPlayer.duration < seekTo) {
        resolve(
          'https://d2uolguxr56s4e.cloudfront.net/img/kartrapages/video_player_placeholder.gif'
        );
        return;
      }

      setTimeout(() => {
        videoPlayer.currentTime = seekTo;
      }, 200);

      videoPlayer.addEventListener('seeked', () => {
        const canvas = document.createElement('canvas');
        canvas.width = videoPlayer.videoWidth;
        canvas.height = videoPlayer.videoHeight;

        const ctx = canvas.getContext('2d');
        if (ctx) {
          ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);

          // Convert canvas image to data URL
          const dataURL = canvas.toDataURL('image/jpeg', 0.75);
          resolve(dataURL);
        } else {
          resolve(
            'https://d2uolguxr56s4e.cloudfront.net/img/kartrapages/video_player_placeholder.gif'
          );
        }
      });
    });
  });
}
