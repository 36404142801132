import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Fab, useTheme } from '@mui/material';
import { NetworkCheck, Check } from '@mui/icons-material';

interface IProps {
  loading: boolean;
  success: boolean;
}

const CircularIntegration = ({ loading, success }: IProps & any) => {
  const theme = useTheme();

  return !loading ? null : (
    <div
      className="fixed w-full h-full top-0"
      style={{ backgroundColor: 'rgba(105, 105, 105, 0.2)', zIndex: 1400 }}
    >
      <Fab
        aria-label="status"
        size={'small'}
        color="primary"
        className="m-auto left-0 top-0 right-0 bottom-0 fixed bg-white"
      >
        {success ? <Check color="primary" /> : <NetworkCheck color="primary" />}
      </Fab>
      {loading && (
        <CircularProgress
          size={68}
          color="primary"
          style={{ color: theme.palette.primary.light }}
          className={'m-auto left-0 top-0 right-0 bottom-0 fixed'}
        />
      )}
    </div>
  );
};

CircularIntegration.propTypes = {
  loading: PropTypes.bool,
  success: PropTypes.bool
};

CircularIntegration.defaultProps = {
  loading: false,
  success: false
};

export default CircularIntegration;
