import { combineReducers, Reducer } from 'redux';
import loadingReducer from './reducers/loadingReducer';
import commonReducer from './reducers/commonReducer';
import authReducer from './reducers/authReducer';
import profileReducer from './reducers/profileReducer';
import { Action, State } from '../shared/interface';
import eventReducer from './reducers/eventReducer';

// @ts-ignore
const rootReducer: Reducer<State, Action> = combineReducers({
  loading: loadingReducer,
  event: eventReducer,
  common: commonReducer,
  auth: authReducer,
  profile: profileReducer
});

export default rootReducer;
