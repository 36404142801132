import {
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  SET_COMPANY_BALANCES,
  SET_COMPANY_DETAILS,
  SET_TOKENS,
  SET_USER_CLIENT_SECRET,
  SET_USER_SUBSCRIPTION_COMPLETE
} from '../actions/authActions';
import { Action, AuthState } from '../../shared/interface';
import { Reducer } from 'redux';
import storageService from '../../shared/services/storage.service';
import { STRINGS } from '../../shared/constants/constants';

const initialState: AuthState = {
  isAuthenticated: false,
  user: {
    uuid: '',
    email: '',
    username: '',
    hasCompany: false,
    authProvider: '',
    clientSecret: '',
    subscriptionComplete: false,
    company: {
      uuid: '',
      name: '',
      currency: 'UGX',
      location: '',
      support_number: '',
      country: '',
      business_email: '',
      company_document_one: null,
      company_document_two: null,
      company_document_three: null,
      national_id: null,
      has_active_subscription: false,
      latest_subscription_package: null,
      balances: null,
      logo: null,
      description: ''
    }
  },
  tokens: {
    access: '',
    refresh: ''
  }
};

const authReducer: Reducer<AuthState, Action> = (
  state = initialState,
  action: Action
) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        user: {
          ...state.user,
          ...action.payload
        }
      };

    case SET_COMPANY_DETAILS:
      return {
        ...state,
        user: {
          ...state.user,
          hasCompany: true,
          company: { balances: state.user.company.balances, ...action.payload }
        }
      };
    case SET_COMPANY_BALANCES:
      return {
        ...state,
        user: {
          ...state.user,
          company: { ...state.user.company, balances: action.payload }
        }
      };
    case SET_USER_CLIENT_SECRET:
      return {
        ...state,
        user: { ...state.user, clientSecret: action.payload }
      };
    case SET_USER_SUBSCRIPTION_COMPLETE:
      return {
        ...state,
        user: { ...state.user, subscriptionComplete: action.payload }
      };
    case LOGOUT_SUCCESS:
      storageService.delete(STRINGS.TOKEN_KEY);
      storageService.delete(STRINGS.USER_KEY);
      return {
        ...state,
        ...initialState
      };
    case SET_TOKENS:
      storageService.set(STRINGS.TOKEN_KEY, action.payload.access);
      return {
        ...state,
        tokens: action.payload
      };
    default:
      return state;
  }
};

export default authReducer;
