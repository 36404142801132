import red from '@mui/material/colors/red';
import { createTheme } from '@mui/material/styles';

const primary = '#260155';

const AppTheme = createTheme({
  typography: {
    fontFamily: [
      'Montserrat',
      'Lato',
      'Roboto',
      '-apple-system',
      'BlinkMacSystemFont',
      'Segoe UI',
      'Arial',
      'sans-serif'
    ].join(',')
  },
  palette: {
    secondary: {
      light: '#fff',
      main: '#fff',
      dark: '#fff',
      contrastText: '#fff'
    },
    primary: {
      main: primary,
      contrastText: '#fff'
    },
    error: {
      light: red[300],
      main: red[500],
      dark: red[500]
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '1rem'
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: '#db3131',
          '&$error': {
            color: '#db3131'
          }
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          color: primary,
          '&.MuiOutlinedInput-root': {
            padding: '4px 15px'
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: '10px 12px',
          borderRadius: '.3rem'
        },
        notchedOutline: {
          outlineColor: '#F3F4F6FF',
          borderColor: '#F3F4F6FF',
          borderRadius: '.3rem'
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: primary
        }
      }
    },
    MuiFab: {
      styleOverrides: {
        sizeSmall: {
          textTransform: 'none'
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 5,
          marginTop: 8,
          boxShadow:
          // eslint-disable-next-line max-len
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
          '&.MuiDrawer-paper': {
            borderRadius: 0,
            marginTop: 0,
            boxShadow: 'none'
          },
          '&.MuiAppBar-root': {
            borderRadius: 0,
            marginTop: 0
          }
        }
      }
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          padding: '8px 16px'
        }
      }
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          paddingTop: 0,
          paddingBottom: 0
        },
        gutters: {
          paddingRight: '8px',
          paddingLeft: '8px'
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          '&.Mui-required': {
            color: 'red'
          }
        }
      }
    }
  }
});

export default AppTheme;
