import { Box, Card, CardContent, Skeleton } from '@mui/material';
import React from 'react';

const SubscriptionPackagesSkeleton = () => {
  const skeletons = Array(3).fill(null);

  return (
    <Box
      className="flex flex-col items-center justify-center min-h-screen bg-gray-50 p-4"
      sx={{ height: '90vh' }}
    >
      <Skeleton variant="text" width={200} height={40} className="mb-8" />
      <Box className="flex space-x-4 flex-row align-middle justify-center">
        {skeletons.map((_, index) => (
          <Card
            key={index}
            className={
              index === 1
                ? 'max-w-sm p-4 border-2 primary-border relative'
                : 'max-w-sm p-4 border-2 border-gray-300'
            }
            sx={{
              maxHeight: index === 1 ? '75vh' : '70vh',
              mt: index === 1 ? undefined : '3vh'
            }}
          >
            {index === 1 && (
              <Box
                className="absolute top-2 right-2 bg-primary text-white px-2 py-1 rounded-full"
                sx={{
                  bgcolor: 'primary.main',
                  color: 'white',
                  p: 1,
                  borderRadius: '12px'
                }}
              >
                <Skeleton variant="text" width={100} />
              </Box>
            )}
            <CardContent>
              <Skeleton
                variant="text"
                width={150}
                height={30}
                className="mb-4"
              />
              <Skeleton
                variant="text"
                width={100}
                height={25}
                className="mb-6"
              />
              <Box className="list-disc pl-5 mb-6 text-left">
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
              </Box>
              <Skeleton variant="rectangular" width="100%" height={40} />
            </CardContent>
          </Card>
        ))}
      </Box>
    </Box>
  );
};

export default SubscriptionPackagesSkeleton;
