import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import {
  Menu as MenuClosedIcon,
  MenuOpen as MenuOpenIcon
} from '@material-ui/icons';
import {
  Avatar,
  Box,
  CssBaseline,
  Dialog,
  DialogContent,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  styled,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery
} from '@mui/material';
import { LanguageOutlined } from '@mui/icons-material';
import AppSideMenu from '../../shared/components/side-menu';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { useDispatch, useSelector } from 'react-redux';
import { LOGOUT_SUCCESS } from '../../store/actions/authActions';
import { useTheme } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { State } from '../../shared/interface';
import AuthStorage from '../../shared/utils/authStorage';
import { PATHS } from '../../router/routes';
import CreateEventBtn from '../../shared/components/create-event-btn';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { STRIPE_PUBLISHABLE_KEY } from '../../shared/constants/constants';
import CompletionSubscription from '../../pages/subscriptions/completed';
import CheckoutForm from '../../pages/subscriptions/checkout-form';
import { SET_ACTIVE_EVENT } from '../../store/actions/eventActions';
import WalletBalance from '../../shared/components/wallet-balance';

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);
// eslint-disable-next-line prettier/prettier,max-len
const uuidRegex = /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-4[0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}/;

export const drawerWidth = 240;

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open'
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  }),
  ...(!open && {
    marginLeft: `calc(${theme.spacing(8)} + 1px)`,
    width: `calc(100% - ${`calc(${theme.spacing(8)} + 1px)`})`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));

// 'Profile', 'Account', 'Dashboard',
const settings = ['Logout'];

interface LayoutProps {
  children: ReactNode;
}

function Layout({ children }: LayoutProps) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const user = useSelector((state: State) => state.auth.user);
  const isDesktop = useMediaQuery(theme.breakpoints.down('md'));
  const [isDrawerOpen, setIsDrawerOpen] = useState(isDesktop);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const isAuthenticated = useSelector(
    (state: State) => state.auth.isAuthenticated
  );
  const { clientSecret, subscriptionComplete } = useSelector(
    (state: State) => state.auth.user
  );

  const eventName = useSelector(
    (state: State) => state.event.activeEvent?.event?.name as string
  );

  const toggleDrawer = useCallback(() => {
    setIsDrawerOpen(!isDrawerOpen);
  }, [isDrawerOpen]);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = (setting?: string) => {
    if (setting) {
      if (setting.toLowerCase() === 'logout') {
        dispatch({ type: LOGOUT_SUCCESS, payload: {} });
      }
    }
    setAnchorElUser(null);
  };

  useEffect(() => {
    const token = AuthStorage.getAccessToken();
    if (isAuthenticated && user && token) {
      if (user.hasCompany) {
        if (!location.pathname.includes(PATHS.settings)) {
          if (!location.pathname.includes('subscriptions')) {
            if (!user.company.has_active_subscription && !!user.company.name) {
              navigate('/subscriptions', {
                state: { from: 'none' }
              });
            }
          }
        }
      } else {
        if (!location.pathname.includes(PATHS.settings)) {
          if (user && !user.hasCompany) {
            navigate(PATHS.settings);
          }
        }
      }
    } else {
      navigate('/auth', {
        state: { from: 'none' }
      });
    }
  }, [isAuthenticated, navigate, user, location]);

  useEffect(() => {
    const { pathname } = location;
    if (
      !(
        pathname.includes('/event/') &&
        uuidRegex.test(pathname.split('/event/')[1])
      )
    ) {
      dispatch({
        type: SET_ACTIVE_EVENT,
        payload: { event: null }
      });
    }
  }, [location, dispatch]);

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh' }}>
      <CssBaseline />
      <AppBar position="fixed" color="secondary" open={isDrawerOpen}>
        <Toolbar>
          <IconButton
            edge="start"
            color="primary"
            aria-label="menu"
            onClick={toggleDrawer}
          >
            {isDrawerOpen ? <MenuOpenIcon /> : <MenuClosedIcon />}
          </IconButton>
          <Typography variant="h6" className="ml-2 capitalize" color="primary">
            {eventName ||
              window.location.pathname.split('/').at(-1) ||
              'Dashboard'}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <WalletBalance />
          <CreateEventBtn />
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <IconButton
              size="large"
              aria-label="switch language"
              color="primary"
            >
              <LanguageOutlined />
            </IconButton>
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              className="mt-8"
              id="menu-app-bar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              open={Boolean(anchorElUser)}
              onClose={() => handleCloseUserMenu()}
            >
              {settings.map(setting => (
                <MenuItem
                  key={setting}
                  onClick={() => handleCloseUserMenu(setting)}
                >
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <AppSideMenu isDrawerOpen={isDrawerOpen} />
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 2, mt: 8, position: 'relative' }}
      >
        <Box
          sx={{
            flex: 1,
            minHeight: '89vh',
            width: location.pathname.includes('event')
              ? `calc(100% - ${`calc(${theme.spacing(8)} + 1px)`})`
              : 'initial',
            mr: location.pathname.includes('event')
              ? `calc(${theme.spacing(8)} + 1px)`
              : 0
          }}
        >
          <Paper sx={{ minHeight: 'inherit' }} className="shadow-md p-2">
            {!!clientSecret ? (
              <Elements stripe={stripePromise} options={{ clientSecret }}>
                <Dialog open={true}>
                  <DialogContent>
                    {subscriptionComplete ? (
                      <CompletionSubscription stripePromise={stripePromise} />
                    ) : (
                      <CheckoutForm />
                    )}
                  </DialogContent>
                </Dialog>
              </Elements>
            ) : null}
            {children}
          </Paper>
        </Box>
      </Box>
    </Box>
  );
}

export default Layout;
