import React from 'react';
import {
  Box,
  Button,
  Grid,
  Link,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery
} from '@mui/material';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import loginValidator from '../../shared/validations/sigin';
import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import httpService from '../../shared/services/http.service';
import { API_CONFIG } from '../../shared/constants/constants';
import { useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import {
  fetchCompanyDetails,
  setAuthenticated,
  setTokens
} from '../../store/actions/authActions';
import { useNavigate } from 'react-router-dom';

const SignUp = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const onSubmit = (values: any) => {
    // Handle form submission logic here
    console.log('Form submitted with values:', values);
  };

  const onGoogleSignIn = async ({ credential }: CredentialResponse) => {
    const response = await httpService.post(API_CONFIG.PATH.GOOGLE_AUTH, {
      auth_token: credential,
      user_type: 'B2B'
    })(dispatch, true);
    dispatch(setTokens(response.tokens));
    dispatch(
      setAuthenticated({
        uuid: response.uuid,
        email: response.email,
        username: response.username,
        hasCompany: response.has_company,
        authProvider: response.auth_provider
      })
    );
    if (response.has_company) {
      fetchCompanyDetails(dispatch).then(res => {
        if (!res.has_active_subscription) {
          navigate('/subscriptions', {
            state: { from: 'none' }
          });
        }
      });
    } else {
      navigate('/settings', {
        state: { from: 'none' }
      });
    }
  };

  const OnGoogleSignInFailure = () => {
    console.log('failed');
  };

  return (
    <>
      <Typography variant="h4">Let&apos;s Get Started</Typography>
      <Typography variant="body1" className="my-4 text-gray-500">
        Create your Account
      </Typography>
      <Formik
        initialValues={{ email: '', country: '', password: '' }}
        validationSchema={loginValidator}
        onSubmit={onSubmit}
      >
        <Form
          style={{ width: isMobile ? '85%' : '80%', margin: 'auto' }}
          className="flex flex-col justify-center"
        >
          <Grid xs={12}>
            <Field
              as={TextField}
              type="email"
              label="Email"
              name="email"
              fullWidth
              margin="normal"
              helperText={<ErrorMessage name="email" />}
            />
          </Grid>
          <Grid xs={12}>
            <Field
              as={TextField}
              select
              label="Select Country"
              name="country"
              fullWidth
              margin="normal"
              helperText={<ErrorMessage name="country" />}
            >
              <MenuItem value="usa">USA</MenuItem>
              <MenuItem value="canada">Canada</MenuItem>
              <MenuItem value="uk">UK</MenuItem>
            </Field>
          </Grid>
          <Grid xs={12}>
            <Field
              as={TextField}
              type="password"
              label="Password"
              name="password"
              fullWidth
              margin="normal"
              helperText={<ErrorMessage name="password" />}
            />
          </Grid>
          <Grid xs={12} className="mt-2">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disableElevation
              fullWidth
            >
              Continue
            </Button>
          </Grid>
        </Form>
      </Formik>
      <Grid xs={12} className="mt-5 flex flex-col justify-center">
        <Typography variant="body1" className="text-gray-500">
          By continuing you agree to our{' '}
        </Typography>
        <Typography variant="body1" className="">
          Terms & Conditions and Privacy Policy
        </Typography>
        <Typography className="my-5">Or</Typography>
        <Box sx={{ margin: 'auto' }}>
          <GoogleLogin
            onSuccess={onGoogleSignIn}
            onError={OnGoogleSignInFailure}
            text="signup_with"
            logo_alignment="center"
            cancel_on_tap_outside={true}
            auto_select={true}
            theme="filled_blue"
          />
        </Box>
      </Grid>
      <Grid xs={12} className="mt-10 mb-2 flex flex-row justify-center">
        <Typography>Already have an account? </Typography>
        <Link href="#" underline="always" className="pl-2 capitalize font-bold">
          Log In
        </Link>
      </Grid>
    </>
  );
};

export default SignUp;
