import { Box, Button, Stack } from '@mui/material';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { IEventLiveImage, IEventVideo } from '../../../../shared/interface';
import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';

type ContentType = 'snap' | 'clip';

interface IProps {
  contentType: ContentType;
  content: IEventLiveImage | IEventVideo;
  onDelete: () => void; // Callback function to handle delete action
}

const ImagesVideoWorkspace: React.FC<IProps> = ({
  contentType,
  content,
  onDelete
}: IProps) => {
  return (
    <Stack
      sx={{ flexGrow: 1, padding: '0 50px 10px', position: 'relative' }}
      justifyContent="center"
      alignItems="center"
    >
      {content && (
        <Box sx={{ position: 'absolute', top: 10, right: 10 }}>
          <Button
            color="error"
            onClick={onDelete}
            size="small"
            className="capitalize"
            variant="contained"
            startIcon={<DeleteIcon />}
          >
            Delete
          </Button>
        </Box>
      )}
      {!!content ? (
        contentType === 'snap' ? (
          <TransformWrapper>
            <TransformComponent>
              <img
                src={(content as IEventLiveImage).image}
                alt={'event-pix-2030200'}
                style={{
                  width: '600px',
                  height: '400px',
                  objectFit: 'contain'
                }}
              />
            </TransformComponent>
          </TransformWrapper>
        ) : (
          <video controls style={{ width: '600px', height: '400px' }}>
            <source src={(content as IEventVideo).file} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )
      ) : (
        ''
      )}
    </Stack>
  );
};

export default ImagesVideoWorkspace;
