const actionTypes = {
  API_REQUEST_INIT: 'API_REQUEST_INIT',
  API_REQUEST_SUCCESS: 'API_REQUEST_SUCCESS',
  API_REQUEST_FAIL: 'API_REQUEST_FAIL',

  SET_NOTIFICATION: 'SET_NOTIFICATION',
  RESET_NOTIFICATION: 'RESET_NOTIFICATION',

  SHOW_LOADER: 'SHOW_LOADER',
  HIDE_LOADER: 'HIDE_LOADER',

  CURRENT_USER: 'CURRENT_USER',

  FETCH_EVENTS_SUCCESS: 'FETCH_EVENTS_SUCCESS'
};

export default actionTypes;
