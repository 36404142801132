import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { Box, Drawer, IconButton, Stack, Typography } from '@mui/material';
import { Close, Fullscreen, FullscreenExit } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

interface IProps {
  title: string;
  content: ReactNode;
  handleCloseDrawer: () => void;
  canExpand?: boolean;
  backGroundColor?: string;
}

const EventWorkSpace: React.FC<IProps> = ({
  title,
  content,
  handleCloseDrawer,
  canExpand = false,
  backGroundColor = 'black'
}: IProps) => {
  const theme = useTheme();
  const [isFullscreen, setIsFullscreen] = useState(false);
  const workspaceWidth = useMemo(() => {
    if (isFullscreen) {
      return `calc(100% - ${`calc(${theme.spacing(8)} + 1px)`})`;
    }
    return '45vw';
  }, [isFullscreen, theme]);

  useEffect(() => {
    if (content == null) {
      setIsFullscreen(false);
    }
  }, [content]);

  return (
    <Drawer
      anchor="right"
      open={!!content}
      onClose={handleCloseDrawer}
      ModalProps={{
        BackdropProps: {
          sx: {
            backgroundColor: 'transparent'
          }
        }
      }}
      PaperProps={{
        sx: {
          width: workspaceWidth,
          marginRight: isFullscreen ? 0 : '60px',
          marginLeft: isFullscreen ? '60px' : 0,
          backgroundColor: backGroundColor,
          paddingTop: '60px'
        }
      }}
    >
      <Stack
        sx={{
          padding: '8px 4px',
          backgroundColor: '#F8F8FF'
        }}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography
          className="pl-2"
          sx={{
            fontSize: '16px',
            fontWeight: '600',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            flex: 1
          }}
        >
          {title}
        </Typography>
        <Box>
          {canExpand ? (
            <IconButton onClick={() => setIsFullscreen(!isFullscreen)}>
              {isFullscreen ? (
                <FullscreenExit color="primary" />
              ) : (
                <Fullscreen color="primary" />
              )}
            </IconButton>
          ) : null}

          <IconButton onClick={handleCloseDrawer}>
            <Close color="primary" />
          </IconButton>
        </Box>
      </Stack>
      {content}
    </Drawer>
  );
};

export default EventWorkSpace;
