import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  CircularProgress,
  Collapse,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  Pagination,
  Paper,
  Typography
} from '@mui/material';
import { Close, DoubleArrow } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { INewEventContent, State } from '../../../../../shared/interface';
import {
  UPDATE_EVENT_NEW_IMAGES,
  UPDATE_EVENT_NEW_VIDEOS
} from '../../../../../store/actions/eventActions';
import httpService from '../../../../../shared/services/http.service';
import { API_CONFIG } from '../../../../../shared/constants/constants';
import { useEventLiveImages, useEventVideos } from '../../hooks';

const itemsPerPage = 5;

const createFormData = (images: INewEventContent[], name: string): FormData => {
  const formData = new FormData();
  images.forEach((image, index) => {
    formData.append(name, image.file);
  });
  return formData;
};

interface IEventImageUploadStatusProps {
  contentType: 'Images' | 'Videos';
}

const EventImageUploadStatus: React.FC<IEventImageUploadStatusProps> = ({
  contentType
}) => {
  const dispatch = useDispatch();
  const { mutateEventVideos } = useEventVideos();
  const { mutateEventLiveImages } = useEventLiveImages();
  const eventUuid = useSelector(
    (state: State) => state.event.activeEvent?.event?.uuid
  );

  const { items, count, uploaded } = useSelector((state: State) => {
    if (contentType === 'Images') {
      return state.event.activeEvent?.images?.newImages;
    }
    return state.event.activeEvent?.videos?.newVideos;
  });

  const [minimized, setMinimized] = useState(false);
  const [page, setPage] = useState(1);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(false);

  const startIndex = useMemo(() => (page - 1) * itemsPerPage, [page]);
  const endIndex = useMemo(() => startIndex + itemsPerPage, [startIndex]);
  const paginatedImages = useMemo(
    () => items.slice(startIndex, endIndex),
    [endIndex, items, startIndex]
  );

  const progress = useMemo(() => {
    return Math.ceil((uploaded / count) * 100);
  }, [count, uploaded]);

  const updatedItemsCount = useCallback(
    (item: INewEventContent, uploadedStatus = false) => {
      dispatch({
        type:
          contentType === 'Images'
            ? UPDATE_EVENT_NEW_IMAGES
            : UPDATE_EVENT_NEW_VIDEOS,
        payload: {
          images: items.filter(itm => itm.id !== item.id),
          count: uploadedStatus ? count : count - 1,
          uploaded: uploadedStatus ? uploaded + 1 : uploaded
        }
      });
    },
    [items, dispatch, count, uploaded, contentType]
  );

  const toggleMinimized = () => {
    setMinimized(prev => !prev);
  };

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleUpload = useCallback(async () => {
    if (items.length === 0) return;
    setUploading(true);
    try {
      const formData = createFormData(
        [items[0]],
        contentType === 'Images' ? 'image' : 'file'
      );

      await httpService.post(
        API_CONFIG.PATH.EVENTS[
          contentType === 'Images' ? 'POST_EVENT_IMAGES' : 'POST_EVENT_VIDEOS'
        ](eventUuid as string),
        formData,
        {},
        { contentType: 'multipart/form-data' }
      )(dispatch, false);
    } catch (e) {
      setError(!!e);
      console.error('Error uploading image:', e);
    } finally {
      updatedItemsCount(items[0], true);
      if (contentType === 'Images') {
        mutateEventLiveImages().then();
      } else {
        mutateEventVideos().then();
      }
      setUploading(false);
    }
  }, [
    contentType,
    dispatch,
    eventUuid,
    items,
    mutateEventLiveImages,
    mutateEventVideos,
    updatedItemsCount
  ]);

  useEffect(() => {
    if (!uploading && !error) {
      handleUpload().then();
    }
  }, [uploading, error, handleUpload, items]);

  if (items.length === 0) return null;

  return (
    <Box
      position="fixed"
      bottom={0}
      right={40}
      zIndex={2}
      mr={2}
      className="px-2"
      sx={{ minWidth: '20vw' }}
    >
      <Paper>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p={1}
        >
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
            Uploading Status
          </Typography>
          <IconButton
            onClick={toggleMinimized}
            sx={{ transform: minimized ? 'rotate(-90deg)' : 'rotate(90deg)' }}
          >
            <DoubleArrow />
          </IconButton>
        </Box>
        <Box sx={{ width: '95%', mx: 'auto', mb: 2 }}>
          <LinearProgress variant="determinate" value={progress} />
        </Box>
        <Collapse in={!minimized}>
          <List>
            {paginatedImages.map(item => (
              <ListItem key={item.id} disablePadding className="my-1">
                <ListItemAvatar>
                  <img
                    alt={item.name}
                    src={item.preview}
                    style={{ width: 40, height: 40 }}
                  />
                </ListItemAvatar>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Typography>{item.name || item.file.name}</Typography>
                  {uploading && item.preview === items[0].preview ? (
                    <Box sx={{ ml: 1, display: 'flex', alignItems: 'center' }}>
                      <CircularProgress size={20} />
                    </Box>
                  ) : (
                    <IconButton onClick={() => updatedItemsCount(item)}>
                      <Close />
                    </IconButton>
                  )}
                </Box>
              </ListItem>
            ))}
          </List>
          <Box className="flex flex-row justify-end align-middle">
            <Pagination
              count={Math.ceil(items.length / itemsPerPage)}
              page={page}
              onChange={handleChangePage}
            />
          </Box>
        </Collapse>
      </Paper>
    </Box>
  );
};

export default EventImageUploadStatus;
