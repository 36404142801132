import * as Yup from 'yup';

import { errorMessages } from './messages';
const loginValidator = Yup.object()
  .strict(true)
  .shape({
    email: Yup.string()
      .required(errorMessages.required('Email'))
      .matches(
        /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/,
        errorMessages.validType('Email')
      ),
    country: Yup.string().required(errorMessages.validType('Country')),
    password: Yup.string()
      .required(errorMessages.required('Password'))
      .min(6, errorMessages.minLengthString('Password', 4))
  });

export default loginValidator;
